import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountService } from 'src/app/RxJs/account.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  paramData;
  accountData = null;
  otherAccountsData = null;
  constructor(private accountService: AccountService) { }

  @Input()
  set passDataInComponent(paramData) {
    if (!paramData) {
      return;
    }
    this.paramData = paramData;
    this.getAccountSummary();
    this.getOtherAccounts();
  }

  ngOnInit() {
  }

  getAccountSummary() {
    const param = { idNumber: this.paramData.idNumber };
    this.accountService.accountSummary(param).subscribe((data: any) => {
      this.accountData = data;
    },
      (err: HttpErrorResponse) => {
      });
  }

  getOtherAccounts() {
    const param = { idNumber: this.paramData.idNumber };
    this.accountService.otherAccounts(param).subscribe((data: any) => {
      this.otherAccountsData = data;
    },
      (err: HttpErrorResponse) => {
      });
  }

}
