export enum CommunicationLogTransferType {
    CreditScore = 'Credit Score',
    AccountInformation = 'Account Information',
    AdverseInformation = 'Adverse Information',
    DeclinedCredit = 'Declined credit',
    EscalateToVericred = 'Escalate to Vericred',
    Loans = 'Loans',
    DB = 'DB',
    SavingsInvestments = 'Savings & Investments',
    Registration = 'Registration',
    CallBack = 'Call Back',
    AbandonedChats = 'Abandoned Chats',
    AfterhoursChats = 'Afterhours Chats',
    FollowUp = 'FollowUp',
    NoLongerInterested = 'No Longer Interested',
    Unanswered = 'Unanswered',
    InvalidNumber = 'Invalid Number',
    WingfieldFinance = 'Wingfield Finance',
    WingfieldREFinance = 'Wingfield RE-Finance',
    BestPriceForMyCar = 'Best price for my car',
    RentToBuy = 'Rent to buy',
    DCLite = 'DC Lite',
    TransferDebtBusters = 'Transfer - DebtBusters',
}

export const CommunicationLogTransferTypeMapping = [
    { type: CommunicationLogTransferType.CreditScore, value: 1 },
    { type: CommunicationLogTransferType.AccountInformation, value: 2 },
    { type: CommunicationLogTransferType.AdverseInformation, value: 3 },
    { type: CommunicationLogTransferType.DeclinedCredit, value: 4 },
    { type: CommunicationLogTransferType.EscalateToVericred, value: 5 },
    { type: CommunicationLogTransferType.Loans, value: 6 },
    { type: CommunicationLogTransferType.DB, value: 7 },
    { type: CommunicationLogTransferType.SavingsInvestments, value: 8 },
    { type: CommunicationLogTransferType.Registration, value: 9 },
    { type: CommunicationLogTransferType.CallBack, value: 10 },
    { type: CommunicationLogTransferType.AbandonedChats, value: 11 },
    { type: CommunicationLogTransferType.AfterhoursChats, value: 12},
    { type: CommunicationLogTransferType.FollowUp, value: 13 },
    { type: CommunicationLogTransferType.NoLongerInterested, value: 14 },
    { type: CommunicationLogTransferType.Unanswered, value: 15 },
    { type: CommunicationLogTransferType.InvalidNumber, value: 16 },
    { type: CommunicationLogTransferType.WingfieldFinance, value: 17 },
    { type: CommunicationLogTransferType.WingfieldREFinance, value: 18 },
    { type: CommunicationLogTransferType.BestPriceForMyCar, value: 19 },
    { type: CommunicationLogTransferType.RentToBuy, value: 20 },
    { type: CommunicationLogTransferType.DCLite, value: 21 },
    { type: CommunicationLogTransferType.DB, value: 22 }
  ];
