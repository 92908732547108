import { Component, OnInit,  Input, ViewChild, ElementRef } from '@angular/core';
import { DashboardService } from 'src/app/RxJs/dashboard.service';
import { ProductService } from 'src/app/RxJs/product.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/RxJs/user.service';
import { CommunicationService } from 'src/app/RxJs/communication.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  creditHistory: any = {}; creditHealthInfo: any = {}; creditHealthInfoUiTextElement: any = {};
  creditHistoryMonths; creditHistoryMonth = '';
  scoreStatus = ''; scoreProgress: any = 0; showMoreAccounts = false; scoreAgeProgress: any = 0; scoreColor = '';
  strokeUrl = 'url(' + window.location.href.substring(0, window.location.href.indexOf('#')) + '#Pattern)';
 
  creditReportYear; creditReportMonth;
 

  personalLoanTick = false;
  homeLoanTick = false;
  carLoanTick = false
  creditCardTick = false;
  retailTick = false;

  personalLoanCross = false;
  homeLoanCross = false;
  carLoanCross = false
  creditCardCross = false;
  retailCross = false;

  personalLoanExclamation = false;
  homeLoanExclamation = false;
  carLoanExclamation = false
  creditCardExclamation = false;
  retailExcalamation = false;

  personalLoanDescription = '';
  homeLoanDescription = '';
  carLoanDescription = '';
  creditCardDescription = '';
  retailDescription = '';

  debtPercentageText = '';
  debtPercentageCross=false;
  debtPercentageTick=false;
  debtConsolidationText='';
  errorMessage = '';
  
  paramData;
  IsShowCapfin=false;
  IsShowSsf=false;

  activeIds: string[] =[];
  panels = [0, 1,2,3,4,5,6];
  dcStatus; splStatus; mobicredStatus; vehicleFinanceStatus; truworthsStatus;oobaStatus; capfinStatus;ssfStatus;
  mobicredBtnName = ''; vehicleFinanceBtnName = ''; vehicleReFinanceBtnName = '';
  vehicleFinanceCampaignSource = 0; vehicleRefinanceCampaignSource = 0;
  modalOptions: NgbModalOptions;
  dbc_Dcrs = 0; monthlyInstalment = 0; grossIncome = 0;
  campaignSourceType;
  callbackErrorMsg;
  callbackSuccessMsg;

  creditLifeInsuranceDescription;
  creditLifeInsuranceDescriptionColor;
  currentPremium;
  ourInsurance;

  @ViewChild('debtConsolidationModel', { static: false }) debtConsolidationModel: ElementRef;
  @ViewChild('creditLifeModel', { static: false }) creditLifeModel: ElementRef;
  CurrentPremium = 0;OurInsurence = 0;
  constructor(private dashboardService: DashboardService,
              private productService: ProductService,
              private modalService: NgbModal,
              private communicationService: CommunicationService) { }
  @Input()
  set passDataInComponent(paramData) {
    if (!paramData) {
      return;
    }
    this.paramData = paramData;
    this.bindDashboard();
  }

  ngOnInit() {

    console.log('this is product page');
    this.activeIds = this.panels.map(p => "panel-"+ p);  
    this.getDebtConsolidation();
  }

  private bindDashboard() {
    this.creditReportYear = new Date().getFullYear();
    this.creditReportMonth = new Date().getMonth() + 1;
    this.creditHistoryMonth = new Date(`${new Date()}`).toLocaleDateString(undefined, { month: 'long' }) + ', ' + this.creditReportYear;
    this.getCreditHealthInfo();
    this.getProductsQualify();
   // this.getCreditLifeInsuranceQuote();
  }

  getCreditHealthInfo() {
    const param = { year: this.creditReportYear, month: this.creditReportMonth, idNumber: this.paramData.idNumber };
    this.dashboardService.creditHealthInfo(param).subscribe((data: any) => {
      this.creditHealthInfo = data.creditHealthInfo;
      this.creditHealthInfoUiTextElement = data.creditHealthInfoUiTextResponse;
      this.personalLoanDescription = data.creditHealthInfoUiTextResponse.personalLoan.description;
      this.homeLoanDescription = data.creditHealthInfoUiTextResponse.creditCoachScoreHomeLoan.description;
      this.carLoanDescription = data.creditHealthInfoUiTextResponse.creditCoachScoreVehicleFinance.description;
      this.creditCardDescription = data.creditHealthInfoUiTextResponse.creditCoachScoreCreditCard.description;
      this.retailDescription = data.creditHealthInfoUiTextResponse.retail!=null? data.creditHealthInfoUiTextResponse.retail.description:'';
      this.getQualify(data.creditHealthInfoUiTextResponse);
      this.getDebtPercentageText(data.creditHealthInfo);
    },
      (err: HttpErrorResponse) => {

      });
  }

  getQualify(scoreElementInformation){

    if(this.personalLoanDescription == 'Very Low Chance' || this.personalLoanDescription == 'Low Chance'){
      this.personalLoanCross = true
    }
    if(this.personalLoanDescription == 'Very High Chance' || this.personalLoanDescription == 'High Chance'){
      this.personalLoanTick = true
    }
    if(this.personalLoanDescription == 'Moderate Chance'){
      this.personalLoanExclamation = true
    }

    if(this.homeLoanDescription == 'Very Low Chance' || this.homeLoanDescription == 'Low Chance'){
      this.homeLoanCross = true
    }
    if(this.homeLoanDescription == 'Very High Chance' || this.homeLoanDescription == 'High Chance'){
      this.homeLoanTick = true
    }
    if(this.homeLoanDescription == 'Moderate Chance'){
      this.homeLoanExclamation = true
    }

    if(this.carLoanDescription == 'Very Low Chance' || this.carLoanDescription == 'Low Chance'){
      this.carLoanCross = true
    }
    if(this.carLoanDescription == 'Very High Chance' || this.carLoanDescription == 'High Chance'){
      this.carLoanTick = true
    }
    if(this.carLoanDescription == 'Moderate Chance'){
      this.carLoanExclamation = true
    }

    if(this.creditCardDescription == 'Very Low Chance' || this.creditCardDescription == 'Low Chance'){
      this.creditCardCross = true
    }
    if(this.creditCardDescription == 'Very High Chance' || this.creditCardDescription == 'High Chance'){
      this.creditCardTick = true
    }
    if(this.creditCardDescription == 'Moderate Chance'){
      this.creditCardExclamation = true
    }

    if(this.retailDescription == 'Very Low Chance' || this.retailDescription == 'Low Chance'){
      this.retailCross = true
    }
    if(this.retailDescription == 'Very High Chance' || this.retailDescription == 'High Chance'){
      this.retailTick = true
    }
    if(this.retailDescription == 'Moderate Chance'){
      this.retailExcalamation = true
    }
  }

  getDebtPercentageText(creditHealthInfo){
    console.log('getDebtPercentageText', creditHealthInfo);
    if(creditHealthInfo.salaryGoingTowardDebtPercent > 80){
      this.debtPercentageText = 'Very Low Chance';
    }
    else if(creditHealthInfo.salaryGoingTowardDebtPercent >= 61 && creditHealthInfo.salaryGoingTowardDebtPercent <= 80){
      this.debtPercentageText = 'Low Chance';
    }
    else if(creditHealthInfo.salaryGoingTowardDebtPercent >= 41 && creditHealthInfo.salaryGoingTowardDebtPercent <= 60){
      this.debtPercentageText = 'Moderate Chance';
    }
    else if(creditHealthInfo.salaryGoingTowardDebtPercent >= 21 && creditHealthInfo.salaryGoingTowardDebtPercent <= 40){
      this.debtPercentageText = 'High Chance';
    }
    else if(creditHealthInfo.salaryGoingTowardDebtPercent >= 0 && creditHealthInfo.salaryGoingTowardDebtPercent <= 20){
      this.debtPercentageText = 'Very High Chance';
    }
    
    if(creditHealthInfo.salaryGoingTowardDebtPercent > 35 && creditHealthInfo.totalCurrentBalance > 35000){
      this.debtConsolidationText = 'High Chance';
      this.debtPercentageCross=false;
      this.debtPercentageTick=true;
    }
    else
    {
      this.debtConsolidationText = 'Low Chance';
      this.debtPercentageCross=true;
      this.debtPercentageTick=false;
    }
  }

  
  getDebtConsolidation() {
    const param = { idNumber: this.paramData.idNumber };
    this.dashboardService.getDebtConsolidation(param).subscribe((data: any) => {
     console.log("Data  ", data);
      this.dbc_Dcrs = data.dbC_DCRS;
      this.grossIncome = data.grossIncome;
      this.monthlyInstalment = data.monthlyInstallment;

    }, (err: HttpErrorResponse) => {
      //this.modalService.dismissAll();
      });
      
    //this.modalService.open(this.debtConsolidationModel, this.modalOptions);
  }

  getCreditLife(){
    //this.modalService.open(this.creditLifeModel ,this.modalOptions);
  }
  
  
  getProductsQualify() {
    const param = { idNumber: this.paramData.idNumber };
    this.productService.getProductsQualify(param).subscribe((data: any) => {
      if(data != null) {
        console.log("Data ", data);
        this.dcStatus = data.dcResponse;
        this.mobicredStatus = data.mobicredResponse;
        this.splStatus = data.splResponse;
        this.vehicleFinanceStatus = data.vehicleResponse;
        this.oobaStatus = data.oobaResponse;
        this.capfinStatus = data.capfinResponse;
        this.truworthsStatus = data.truworthsResponse;
        this.ssfStatus = data.ssfResponse;

        this.getCreditLifeInsuranceQuote(data.creditLifeInsuranceQuoteResponse);
        this.showCapfinProductTile();
        this.showSsfProductTile()
      
      }
    });
  }
  showCapfinProductTile(){
    if (this.capfinStatus != null) {
      switch (this.capfinStatus.criteria) {
        case "0-69": {
          this.IsShowCapfin=false;
          break;
        }
        case "70-1000": {
          this.IsShowCapfin=true;      
          break;
        }
      }
    }
  }
  showSsfProductTile(){
    if(this.ssfStatus != null){
      switch (this.ssfStatus.criteria){
        case "850-10000":{
          this.IsShowSsf=true;
          break;
        }
        case "0-849":{
          this.IsShowSsf=false;
          break;
        }
      }
    }
  }
  getCreditLifeInsuranceQuote(data: any) {
     this.ourInsurance = data.ourInsurance;
     this.currentPremium = data.currentPremium;

      if(this.ourInsurance >= this.currentPremium)
      {
        this.creditLifeInsuranceDescription = "You have a <u>LOW</u> chance of being successful";
        this.creditLifeInsuranceDescriptionColor = "#8D8D8D";
      }
      else
      {
        this.creditLifeInsuranceDescription = "You have a <u>HIGH</u> chance of being successful";
        this.creditLifeInsuranceDescriptionColor = "#82C320";
      }
  }
}
