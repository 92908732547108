import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor(private http: HttpClient) { }

  transferUserToSpl(data: any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcCommunication/TransferUserToSpl', data);
  }

  transferUserToSplEzFlow(data: any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcCommunication/TransferUserToSplEzFlow', data);
  }

  getAgentCommunicationPreferences(params: any) {
    return this.http.get(environment.ccAgentWebApiUrl + 'api/CcCommunication/GetAgentCommunicationPreferences', { params });
  }
  TransferUserToDebtbuster(data: any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcCommunication/TransferUserToDebtbuster', data);
  }
  GenerateOobaLeads(idNumber: string) {
    return this.http.post(environment.ccAgentWebApiUrl + `api/CcCommunication/CreateOobaLeads?idNumber=${idNumber}`, {});
  }
  

}
