import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private adalService: MsAdalAngular6Service
  ) { }

  totalRequests = 0;
  completedRequests = 0;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    this.spinner.show();
    this.totalRequests++;

    if (this.adalService.accessToken) {
      request = request.clone({
        headers: this.addExtraHeaders(request.headers)
      });

      return next.handle(request).pipe(
        catchError(
          err =>
            new Observable<HttpEvent<any>>(observer => {
              if (err instanceof HttpErrorResponse) {
                const errResp = err as HttpErrorResponse;
                if (errResp.status === 401 || errResp.status === 0) {
                  this.adalService.logout();
                }
              }
              observer.error(err);
              observer.complete();
            })
        )
      ).pipe(
        finalize(() => { 
          this.completedRequests++;
          console.log(this.completedRequests, this.totalRequests);
          if (this.completedRequests === this.totalRequests) {
  
            this.spinner.hide(); 
            this.completedRequests = 0;
            this.totalRequests = 0;
          }
         })
      );
    } else {
      this.router.navigateByUrl('/home');
    }
  }

  private addExtraHeaders(headers: HttpHeaders): HttpHeaders {
    headers = headers.append('Version', 'V1');
    headers = headers.append('Authorization', `Bearer ${this.adalService.accessToken}`);
    return headers;
  }
}
