import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  searchUser(data: any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcUser/SearchUser', data);
  }

  updateUser(data: any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcUser/UpdateUserProfile', data);
  }
  activeOrDeactiveUserEvent(data: any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcUser/ActivateOrDeactivateUser', data);
  }

  // This will be used for AgentAPI
  // getLoginLogs(params: any) {
  //    return this.http.get(environment.ccAgentWebApiUrl + 'api/CcUser/GetLastSignInLogWithCountByAzureAdObjectId', {params});
  // }

  // For OPSAPI
  getLoginLogs(params: any) {
    return this.http.get(environment.ccOpsWebApiUrl + 'api/SignInLog/GetLastSignInLogWithCountByAzureAdObjectId', {params});
  }


  getRegistrationLogs(params: any) {
    return this.http.get(environment.ccAgentWebApiUrl + 'api/CcUser/GetRegistrationLogByAzureAdObjectId', {params});
  }

  updateExistingUserFields() {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcUser/ExistingUserWithNewFieldUpdate', null);
  }


}
