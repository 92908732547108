export enum CommunicationLogReasonType {
    CRAccountInformation = 'CR - Account Information',
    CRAdverseInformation = 'CR - Adverse Information',
    CRCreditScore = 'CR - Credit Score',
    CRDeclinedCredit = 'CR - Declined credit',
    CSUFollowUp = 'CSU - Follow Up',
    InsShortTerm = 'Ins - Short Term',
    LDebtConsolidation = 'L - Debt Consolidation',
    LPersonalLoan = 'L - Personal Loan',
    SavingsInvestments = 'Savings & Investments',
    Registration = 'Registration',
    Greetings = 'Greetings',
    FollowUp = 'Follow Up',
    VehicleFinance = 'Vehicle Finance',
    CreditConsolidationLoan = 'Credit - Consolidation Loan'
}
export const CommunicationLogReasonTypeMapping = [
    { type: CommunicationLogReasonType.CRAccountInformation, value: 1 },
    { type: CommunicationLogReasonType.CRAdverseInformation, value: 2 },
    { type: CommunicationLogReasonType.CRCreditScore, value: 3 },
    { type: CommunicationLogReasonType.CRDeclinedCredit, value: 4 },
    { type: CommunicationLogReasonType.CSUFollowUp, value: 5 },
    { type: CommunicationLogReasonType.InsShortTerm, value: 6 },
    { type: CommunicationLogReasonType.LDebtConsolidation, value: 7 },
    { type: CommunicationLogReasonType.LPersonalLoan, value: 8 },
    { type: CommunicationLogReasonType.SavingsInvestments, value: 9 },
    { type: CommunicationLogReasonType.Registration, value: 10 },
    { type: CommunicationLogReasonType.Greetings, value: 11 },
    { type: CommunicationLogReasonType.FollowUp, value: 12 },
    { type: CommunicationLogReasonType.VehicleFinance, value: 13 },
    { type: CommunicationLogReasonType.CreditConsolidationLoan, value: 14 }
];
