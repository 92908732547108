import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import * as $ from 'jquery';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { environment } from 'src/environments/environment';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('logoutModel', { static: false }) logoutModel: ElementRef;
  public modalOptions: NgbModalOptions; 
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  env = environment;
 constructor( 
  private idle: Idle,
  private keepalive: Keepalive,
  private modalService: NgbModal,
  private adalService: MsAdalAngular6Service
  ){
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      keyboard: false
    };
  };
 
  ngOnInit() {
    this.bindNavBar();
    this.autoLogOutPopup();
  }

  bindNavBar() {
    const hamburger = $('.panel-toogl');
    const customProfile = $('.customer-profile');
    
    $(hamburger).click(function(){
      $(customProfile).toggleClass("push-menu-hidden");
    });
  }
  logout(): void {
    this.idle.stop();
    this.adalService.logout();
  }
  autoLogOutPopup() {
    // sets an idle timeout of 3 seconds, for testing purposes.
    this.idle.setIdle(this.env.sessionTimeOut);
    // sets a timeout period of 3 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      // this.idleState = 'No longer idle.';
      // console.log(this.idleState);
      this.idle.stop();
      // this.reset();
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    });

    this.idle.onTimeout.subscribe(() => {
      this.modalService.dismissAll();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.logout();
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve session gone expire!';
      // console.log(this.idleState);
      this.modalService.open(this.logoutModel, this.modalOptions);
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'Your session will time out in ' + countdown + ' seconds.';
      // console.log(this.idleState);
      if (this.modalService.hasOpenModals) {
        this.idle.clearInterrupts();
        // console.log('clear inter');
      }
    });

    // sets the ping interval to 3 seconds
    this.keepalive.interval(15);
    this.reset();
    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
  }
  reset() {
    this.idle.watch();
    this.timedOut = false;
  }
  stay() {
    this.modalService.dismissAll();
    this.reset();
  }
}
