import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(private http: HttpClient) { }

  getActiveCampaign() {
    return this.http.get(environment.ccAgentWebApiUrl +'api/CcCampaign/GetActiveCampaign');
  }

  saveCampaignLookUp(data: any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcCampaign/SaveCampaignLookUp', data );
  }
  getCampaigns(){
    return this.http.get(environment.ccAgentWebApiUrl +'api/CcCampaign/GetCampaigns');
  }

  getActiveCampaigns(){
    return this.http.get(environment.ccAgentWebApiUrl +'api/CcCampaign/GetActiveCampaigns');
  }

  getCampaignBannerById(params:any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcCampaign/GetBannerbyCampaignId',  params );
  }

  getCampaignLookUpById(params: any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcCampaign/GetCampaignLookUpById',  params );
  }

  updatedCampaignById(params: any){
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcCampaign/UpdatedCampaignById',   params );
  }

  campaignBannerBinding(params:any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcCampaign/CampaignBannerBinding',  params );
  }

  getCampaignCategory() {
    return this.http.get(environment.ccAgentWebApiUrl + 'api/CcCampaign/GetCampaignCategories');
  }

  softDeleteCampaign(params:any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcCampaign/DeleteCampaign', params);
  }

  
  getInactiveAndEndedCampaings() {
    return this.http.get(environment.ccAgentWebApiUrl + 'api/CcCampaign/GetInactiveAndEndedCampaings');
  }

  isBannerLinkedToAAtiveCamapaign(params:any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcCampaign/IsBannerLinkedToAActiveCamapaign', params);
  }
}
