// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  sessionTimeOut: 60 * 20,
  ccAgentWebApiUrl: 'https://app-jm-agent-api-dev-001.azurewebsites.net/',
  //ccAgentWebApiUrl: 'https://localhost:44338/',
  ccOpsWebApiUrl: 'https://app-jm-ops-api-dev-001.azurewebsites.net/',
  //ccOpsWebApiUrl: 'https://localhost:44301/',
  azureAdB2BConfig: {
    tenant: '5ef8fc5f-827b-4602-a1cd-ef0803c6b3d7',
    clientId: '618911d1-4b44-4234-b262-d7a8d4ac5a85',
    redirectUri: window.location.origin + '/portal/dashboard',
    navigateToLoginRequestUrl: false,
    cacheLocation: 'localStorage',
    postLogoutRedirectUri: window.location.origin + '/home',
  }   
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
