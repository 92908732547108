import { FormGroup, AbstractControl } from '@angular/forms';
import { RegExValidation } from '../constants/regex-validation.constants';

// custom validator to check that two fields match
export class InputValidationService {

    static isValidMobile(control: AbstractControl) {
        const value = (control.value || '').toString().trim();

        // set error on matchingControl if validation fails
        if (!(RegExValidation.cellNumber.test(value))) {
            return { isValidMobile: true };
        } else {
            return null;
        }
    }

    static isValidIdNumber(control: AbstractControl) {
        const value = control.value ? control.value.toString() : '';

        if (value.length === 0) { return { isValidIdNumber: true }; }

        const tempDate = new Date(value.substring(0, 2), value.substring(2, 4) - 1, value.substring(4, 6));
        if (!((String(tempDate.getFullYear()).substring(2, 4) === value.substring(0, 2)) &&
          (tempDate.getMonth() === Number(value.substring(2, 4)) - 1) &&
          (tempDate.getDate() === Number(value.substring(4, 6))))) {
          return { isValidIdNumber: true };
        } else {
          // Apply Luhn Algorithm for check-digits
          let tempTotal = 0;
          let checkSum = 0;
          let multiplier = 1;
          for (let i = 0; i < 13; ++i) {
            tempTotal = parseInt(value.charAt(i)) * multiplier;
            if (tempTotal > 9) {
              tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
            }
            checkSum = checkSum + tempTotal;
            multiplier = (multiplier % 2 === 0) ? 1 : 2;
          }
          if ((checkSum % 10) !== 0) { return { isValidIdNumber: true }; }
        }

        return null;
    }

    static isValidEmail(control: AbstractControl) {
        const value = (control.value || '').toString().trim();
      
        // set error on matchingControl if validation fails
        if (!(RegExValidation.email.test(value))) {
            return { isValidEmail: true };
        } else {
            return null;
        }
    }

    static isValidText(control: AbstractControl){
        const value = (control.value || '').toString();
        if (value.length === 0) {
            // return if another validator has already found an error on the matchingControl
            return null;
        }

        // set error on matchingControl if validation fails
        if (!(RegExValidation.textName.test(control.value))) {
            return { isValidText: true };
        } else {
            return null;
        }
    }

    static DigitMustNotMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }

            // set error on matchingControl if validation fails
            if (control.value == matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            }
            else {
                matchingControl.setErrors(null);
            }
        };
    }

    static numbersLettersAndUnderscore(control: AbstractControl) {
        if(control.value != null)
        {
            const value = (control.value || '').toString();
            if (value.length === 0) {
                // return if another validator has already found an error on the matchingControl
                return null;
            }

            if (!(RegExValidation.numbersLettersAndUnderscore.test(control.value))) {
                return { numbersLettersAndUnderscore: true };
            } else {
                return null;
            }
    }

   }

    static numbersLettersUnderscoreAndSpace(control: AbstractControl) {
        if(control.value != null)
        {

            const value = (control.value || '').toString();
            if (value.length === 0) {
                // return if another validator has already found an error on the matchingControl
                return null;
            }
        if (!(RegExValidation.numbersLettersUnderscoreAndSpace.test(value))) {
            return { numbersLettersUnderscoreAndSpace: true };
        } else {
            return null;
        }
     }
    }


    static isValidURL(control: AbstractControl) {
        const value = (control.value || '').toString().trim();
      
        // set error on matchingControl if validation fails
        if (!(RegExValidation.urlRegex.test(value))) {
            return { isValidURL: true };
        } else {
            return null;
        }
    }


}
