import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeaderboardbannerService {

  constructor(private http: HttpClient) { }

  saveBannerInfo(data: any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcLeaderBoardBanner/SaveBannerText', data );
  }

  getActiveBanners() {
    return this.http.get(environment.ccAgentWebApiUrl +'api/CcLeaderBoardBanner/GetActiveBanners');
  }



  bindingUsersWithBanner(data: any){
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcLeaderBoardBanner/BindingUsersWithBanner', data );
  }

  getBannerById(params: any){
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcLeaderBoardBanner/GetBannerById',  params );
  }

  updateBannerInfo(params :any){
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcLeaderBoardBanner/UpdateLeaderBoardBanner',   params );
  }

  getInActivedBanners(){
    return this.http.get(environment.ccAgentWebApiUrl +'api/CcLeaderBoardBanner/GetInactiveBanners');
  }

  softDeleteLeaderBoardBaner(params: any){
    return this.http.post(environment.ccAgentWebApiUrl +'api/CcLeaderBoardBanner/DeActiveBanner', params);
  }
  getCampaignProductTileBinding(){
    return this.http.get(environment.ccAgentWebApiUrl +'api/CcLeaderBoardBanner/GetCampaignProductTileBinding');
  }
}
