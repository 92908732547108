import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommunicationLogTypeMapping } from '../../helpers/enums/communication-log-type';
import { CommunicationLogReasonTypeMapping } from '../../helpers/enums/communication-log-reason-type';
import { CommunicationLogTransferTypeMapping } from '../../helpers/enums/communication-log-transfer-type';
import { CommunicationLogService } from '../../RxJs/communication-log.service'; 
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-communication-log',
  templateUrl: './communication-log.component.html',
  styleUrls: ['./communication-log.component.css']
})
export class CommunicationLogComponent implements OnInit {
  @ViewChild('saveNewLogModel', { static: false }) saveNewLogModel: ElementRef;
  @ViewChild('logDetailsModel', { static: false }) logDetailsModel: ElementRef;

  paramData;
  saveNewLogForm: FormGroup;
  communicationLogTypes;
  communicationLogReasonTypes;
  communicationLogTransferTypes;
  successMsgAddLog = '';
  warningMsgAddLog = '';
  communicationLogList = [];
  disableSaveLogButton = true;
  modalOptions: NgbModalOptions;

  constructor(
    private formBuilder: FormBuilder,
    private communicationLogService: CommunicationLogService,
    private modalService: NgbModal,
    ) {
      this.modalOptions = {
        backdrop: 'static',
        backdropClass: 'customBackdrop'
      };
    }

  @Input()
  set passDataInComponent(paramData) {
    if (!paramData) {
      return;
    }
    this.paramData = paramData;
  }

  ngOnInit() {
    this.communicationLogTypes = CommunicationLogTypeMapping;
    this.communicationLogReasonTypes = CommunicationLogReasonTypeMapping;
    this.communicationLogTransferTypes = CommunicationLogTransferTypeMapping;
    this.saveNewLogFormReset();
  }

  private saveNewLogFormReset() {
    this.saveNewLogForm = this.formBuilder.group({
      idNumber: [''],
      type: [1, [Validators.required]],
      reason: [1, [Validators.required]],
      outcome: [''],
      transfer: [1, [Validators.required]]
    });
    this.warningMsgAddLog = '';
    this.successMsgAddLog = '';
  }

  get saveNewLogFormControls() { return this.saveNewLogForm.controls; }


  onChanges(): void {
    this.saveNewLogForm.valueChanges.subscribe(val => {
      this.disableSaveLogButton = false;
    });
  }

  saveNewLog() {
    this.warningMsgAddLog = '';
    this.successMsgAddLog = '';
    if (this.saveNewLogForm.invalid) {
      this.saveNewLogForm.markAllAsTouched();
      this.warningMsgAddLog = 'All fields are required.';
      return;
    }
    this.saveNewLogForm.controls.idNumber.setValue(this.paramData.idNumber);
    console.log(this.saveNewLogForm.value);
    this.communicationLogService.saveCommunicationLog(this.saveNewLogForm.value).subscribe((data: any) => {
      this.saveNewLogFormReset();
      this.successMsgAddLog = 'Log Added Successfully.';
      this.disableSaveLogButton = true;
    }, (err: HttpErrorResponse) => {
      this.warningMsgAddLog = err.message;
    });

  }

  getLogDetails() {
    if (this.paramData != null) {
      const param = { idNumber: this.paramData.idNumber };
      this.communicationLogService.getCommunicationLog(param).subscribe((data: any) => {
        console.log(data);
        this.communicationLogList = data;
      }, (err: HttpErrorResponse) => {

      });
    }
  }

  openLogNewPopup() {
    this.modalService.open(this.saveNewLogModel, this.modalOptions);
    this.saveNewLogFormReset();
    this.onChanges();
  }

  openLogDetailsPopup() {
    this.modalService.open(this.logDetailsModel, { size: 'xl' });
    this.getLogDetails();
  }

}
